/* eslint-disable @typescript-eslint/no-explicit-any */

/**
 * @param arr array
 * @param k number
 * @returns max k random elements from array
 */
export default function getRandom<T>(arr: T[], k: number): T[] {
  let n = k
  let len = arr.length
  if (n > len) n = len

  const result = new Array(n)
  const taken = new Array(len)

  while (n) {
    n -= 1
    const x = Math.floor(Math.random() * len)
    result[n] = arr[x in taken ? taken[x] : x]
    len -= 1
    taken[x] = len in taken ? taken[len] : len
  }
  return result
}
