import React from 'react'

interface Props {
  articleSections: string[]
  visibleSections: boolean[]
}

const ArticleSections: React.FC<Props> = ({
  articleSections,
  visibleSections,
}) => {
  return (
    <div className="other-articles-wrap">
      <div className="other-articles">
        {articleSections.map((a: any, i) => {
          return (
            <div
              key={a}
              className={`o-article ${visibleSections[i] ? 'selected' : ''}`}
            >
              {a}
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default ArticleSections
