import React, { useEffect, useState } from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'
import { Col } from 'react-bootstrap'
import useRummyBanners, {
  RummyBanner,
} from '../../components/Games/Cards/Rummy/useRummyBanners'
import getRandom from '../../lib/k-random-from-array'
import { BannerProps } from '../BannerProps'

const RummyBanners: React.FC<BannerProps> = ({
  openPopup,
  position = 'side',
  language,
}) => {
  const [rummySideBanners, setRummySideBanners] = useState<RummyBanner[]>([])
  const [rummyBottomBanners, setRummyBottomBanners] = useState<RummyBanner[]>(
    []
  )

  const rummyBanners = useRummyBanners()

  useEffect(() => {
    const rummyBannerSide1 = getRandom(
      rummyBanners.filter(p => p.position === 1),
      2
    )
    const rummyBannerSide2 = getRandom(
      rummyBanners.filter(p => p.position === 2),
      2
    )
    const rummyBannerBottom1 = getRandom(
      rummyBanners.filter(p => p.position === 1),
      2
    )
    const rummyBannerBottom2 = getRandom(
      rummyBanners.filter(p => p.position === 2),
      2
    )

    setRummySideBanners([...rummyBannerSide1, ...rummyBannerSide2])
    setRummyBottomBanners([...rummyBannerBottom1, ...rummyBannerBottom2])
  }, [language, rummyBanners])

  const rummySidebarBanners = (
    <>
      <div className="banner1">
        {rummySideBanners[0] ? (
          <div
            className="popupButton"
            aria-hidden="true"
            role="button"
            onClick={openPopup}
            onKeyDown={openPopup}
          >
            <GatsbyImage image={rummySideBanners[0].img} alt="banner" />
          </div>
        ) : null}
      </div>
      <div className="banner2">
        {rummySideBanners[1] ? (
          <div
            className="popupButton"
            aria-hidden="true"
            role="button"
            onClick={openPopup}
            onKeyDown={openPopup}
          >
            <GatsbyImage image={rummySideBanners[1].img} alt="banner" />
          </div>
        ) : null}
      </div>
    </>
  )

  const rummyFooterBanners = (
    <>
      <Col>
        {rummyBottomBanners[2] ? (
          <div className="banner3 banner-spacing">
            <div
              className="popupButton"
              aria-hidden="true"
              role="button"
              onClick={openPopup}
              onKeyDown={openPopup}
            >
              <GatsbyImage image={rummyBottomBanners[2].img} alt="banner" />
            </div>
          </div>
        ) : null}
      </Col>
      <Col>
        {rummyBottomBanners[3] ? (
          <div className="banner3 banner-spacing">
            <div
              className="popupButton"
              aria-hidden="true"
              role="button"
              onClick={openPopup}
              onKeyDown={openPopup}
            >
              <GatsbyImage image={rummyBottomBanners[3].img} alt="banner" />
            </div>
          </div>
        ) : null}
      </Col>
    </>
  )
  return position === 'side' ? rummySidebarBanners : rummyFooterBanners
}

export default RummyBanners
