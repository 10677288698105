import React from 'react'
import { TestimonialProps } from '../../Testimonials/Testimonial'
import HomeTestimonials from './HomeTestimonials'

interface Props {
  testimonials: TestimonialProps[]
}

const TestimonialsContainer: React.FC<Props> = ({ testimonials }) => {
  return (
    <div id="testimonials-container">
      <HomeTestimonials color="black" testimonials={testimonials} />
    </div>
  )
}

export default TestimonialsContainer
