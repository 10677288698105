/* eslint-disable react/no-danger */
import React, { useEffect, useState } from 'react'
import { graphql } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import { Col, Container, Row } from 'react-bootstrap'
import { useLocation } from '@reach/router'
import { Helmet } from 'react-helmet'
import { debounce } from 'lodash'
import Layout from '../components/layout'
import SEO from '../components/seo'
import {
  generateBlogPostingSchema,
  generateBreadcrumbsSchema,
} from '../lib/generate-schema'
import { Breadcrumb } from '../components/Breadcrumbs/Breadcrumbs'
import ArticleSections from './ArticleSections'
import getBreadcrumbs from './getBreadcrumbs'
import BlogLayout from './gameLayouts'
import BlogSimilarArticles from './BlogSimilarArticles'
import getFaqsCMS from '../utils/getFaqsCMS'
import ShareButtons from '../components/Share/Share'
import './BlogTemplate.scss'
import BlogSearchInput from '../components/Blogs/BlogSearchInput'
import BlogList from '../components/Blogs/BlogList'
import useSearch from '../hooks/useSearch'

const BlogPost: React.FC = (context: any) => {
  const { data, pageContext } = context
  const post = data.currPost
  const { similarArticles, prefix, allRummyBlogs } = pageContext

  const prefixes = prefix.split('/')
  const prefixCat = prefixes[prefixes.length - 2]
  const location = useLocation()
  const { title } = post
  const URL = location.href
  const fetchBlog = allRummyBlogs

  const breadcrumbs: Breadcrumb[] = getBreadcrumbs(prefix, post)

  const sections: string[] = post.excerpt.split('\n').filter((s: string) => s)
  const [visibleSections] = useState(Array(sections.length).fill(false))

  const [searchTerm, setSearchTerm] = useState('')
  const [blogsList, setBlogsList] = useState<any>([])

  const search = useSearch({
    searchName: 'title',
    documents: fetchBlog,
    indexFields: ['title'],
  })

  useEffect(() => {
    if (searchTerm) {
      setBlogsList(search.search(searchTerm).slice(0, 10))
    } else {
      setBlogsList([])
    }
  }, [search, searchTerm])

  const updateSearchTerm = debounce((s: string) => {
    setSearchTerm(s)
  }, 500)

  const [newContent, setNewContent] = useState('loading')

  useEffect(() => {
    if (typeof window !== `undefined`) {
      const { html } = post

      const postContent = document?.createElement('div')
      postContent.innerHTML = `${html}`

      const a = postContent?.querySelectorAll('a')

      // eslint-disable-next-line no-plusplus
      for (let idx = 0; idx < a.length; ++idx) {
        if (!(a[idx].href.indexOf('#') > -1)) {
          a[idx].removeAttribute('href')
        }
      }

      const newContentData = postContent.innerHTML
      setNewContent(newContentData)
    }
  }, [post])

  const [searchVisible] = useState(false)

  const faqDataCMS = getFaqsCMS(post) || undefined

  const couponCode = 'NWDB100'

  const blogFeaturedImage = post.feature_image ? (
    <div className="article-poster-image">
      <img src={post.feature_image} alt={post.title} />
    </div>
  ) : (
    <div className="article-poster-image">
      <div className="article-custom-banner">
        <StaticImage
          loading="eager"
          src="../images/feature-image-default.jpg"
          alt={post.title}
          width={730}
        />
        <div className="poster-image-texts">
          <div className="default-title">
            Use <strong>{couponCode}</strong> to get 100% cashback on your 1
            <sup>st</sup> deposit &amp; play Poker!
          </div>
        </div>
      </div>
    </div>
  )

  // adding custom top banner if present, otherwise use regular Featured Images.
  const topBanner = blogFeaturedImage

  const blogPostContent = (
    <Col lg={8}>
      <div className="blogPostContent">
        <h1>{post.title}</h1>
        <div className="post-meta">
          <span>{post.updated_at}</span>
          {/* <span>
            <VernacularBlogReadTime post={post} lang={blogLang} />
          </span> */}
        </div>
        <div className="post-like-languages">
          <ShareButtons title={title} url={URL} />
        </div>
        {topBanner}
        <div dangerouslySetInnerHTML={{ __html: newContent }} />
      </div>

      {/* <AuthorInfo Author={multiPageAuthor} /> */}
      <div className="blogPostContent">
        <div className="post-like-languages">
          <ShareButtons title={title} url={URL} />
        </div>
      </div>
    </Col>
  )

  const blogSimilarArticles = (
    <BlogSimilarArticles
      post={post}
      prefix={prefix}
      prefixCat={prefixCat}
      fetchBlog={fetchBlog}
      similarArticles={similarArticles}
    />
  )

  return (
    <Layout showBreadcrumbs breadcrumbs={breadcrumbs}>
      <SEO
        title={post.meta_title || post.title}
        description={post.meta_description}
        url={`${data.site.siteMetadata.siteUrl + prefix}/${post.slug}`}
        blogPostingSchema={generateBlogPostingSchema({
          title: post.title,
          description: post.meta_description,
          publishedAt: post.published_at,
          createdAt: post.created_at,
          modifiedAt: post.updated_at,
          articleBody: post.html,
          author: post.primary_author.name,
          image: post.feature_image
            ? post.feature_image
            : data.site.siteMetadata.siteUrl + data.defaultOGImage.publicURL,
          url: `${data.site.siteMetadata.siteUrl}${prefix}/${post.slug}`,
        })}
        breadcrumbsSchema={generateBreadcrumbsSchema(breadcrumbs)}
      />
      <Helmet
        meta={[
          {
            name: 'twitter:image',
            content: post.feature_image
              ? post.feature_image
              : data.site.siteMetadata.siteUrl + data.defaultOGImage.publicURL,
          },
          {
            property: 'og:image',
            content: post.feature_image
              ? post.feature_image
              : data.site.siteMetadata.siteUrl + data.defaultOGImage.publicURL,
          },
        ]}
      >
        <link
          rel="canonical"
          href={`${'https://www.getmega.com/rummy/'}${prefix}/${post.slug}/`}
        />
      </Helmet>
      <article id="Post">
        {post.excerpt ? (
          <div className="articles-list">
            <Container>
              <Row>
                <Col className="list">
                  <ArticleSections
                    articleSections={post.excerpt
                      .split('\n')
                      .filter((s: string) => s)}
                    visibleSections={visibleSections}
                  />
                </Col>
              </Row>
            </Container>
          </div>
        ) : null}

        {searchVisible && (
          <div className="blog-list-wrap">
            <Container>
              <BlogSearchInput onChange={s => updateSearchTerm(s)} />
              <div className="blogs-list">
                {blogsList.map((b: any) => {
                  return (
                    <BlogList
                      key={b.id}
                      title={b.title}
                      slug={b.slug}
                      tags={b.tags}
                      prefix={b.prefix}
                      reading_time={b.reading_time}
                      html={b.html}
                      author={b.primary_author.name}
                      feature_image={b.feature_image}
                    />
                  )
                })}
              </div>
            </Container>
          </div>
        )}

        <BlogLayout
          postContent={blogPostContent}
          similarContent={blogSimilarArticles}
          faqData={faqDataCMS}
        />
      </article>
    </Layout>
  )
}

export default BlogPost

export const postQuery = graphql`
  query ($slug: String!) {
    currPost: ghostPost(slug: { eq: $slug }) {
      title
      slug
      feature_image
      html
      excerpt
      custom_excerpt
      meta_title
      meta_description
      tags {
        slug
      }
      published_at
      reading_time
      updated_at(formatString: "MMMM DD, YYYY")
      created_at
      primary_author {
        name
      }
    }
    defaultOGImage: file(
      relativePath: { eq: "share-blog-post/getmega-default.png" }
    ) {
      publicURL
    }
    site {
      siteMetadata {
        siteUrl
      }
    }
  }
`
