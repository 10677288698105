import { RUMMY_VARIANTS } from '../components/internal-links'
import { Breadcrumb } from '../components/Breadcrumbs/Breadcrumbs'

const getParentCrumb = (prefix: string) => {
  const prefixes = prefix.split('/')
  const prefixTitle = prefixes[prefixes.length - 1]

  if (prefixTitle === 'learn') {
    return {
      title: `${
        prefixTitle.charAt(0).toUpperCase() + prefixTitle.slice(1)
      } Rummy`,
      url: `/${prefix}/`,
    }
  }
  return {
    title: prefixTitle.charAt(0).toUpperCase() + prefixTitle.slice(1),
    url: `/${prefix}/`,
  }
}

const getSelfCrumb = (prefix: string, post: { title: any; slug: any }) => ({
  title: post.title,
  url: `/${prefix}/${post.slug}`,
})

const getDashes = new RegExp(/-/g)
const getFirstLetterOfWords = new RegExp(/\b\w/g)

const getSelfCrumbRummy = (prefix: string, post: any) => {
  if (post.slug === 'rummy-variants') {
    return {
      title: 'Variants of Rummy',
      url: `${prefix}/${post.slug}`,
    }
  }
  if (
    post.slug === 'continental-rummy-or-international-rummy' ||
    post.slug === 'international-rummy'
  ) {
    return {
      title: 'International Rummy',
      url: `${prefix}/${post.slug}`,
    }
  }
  return {
    // take post.slug and replace dashes with space, then capitalize the words.
    title: post.slug
      .replace(getDashes, ' ')
      .replace(getFirstLetterOfWords, (l: string) => l.toUpperCase()),
    url: `${prefix}/${post.slug}`,
  }
}

const getBreadcrumbs = (prefix: string, post: any) => {
  const prefixes = prefix.split('/')
  const prefixCat = prefixes[prefixes.length - 2]

  const homepageCrumb = { title: 'Homepage', url: '/' }

  const rummyVariantsCrumb = { title: 'Variants of Rummy', url: RUMMY_VARIANTS }

  const baseRummyBreadcrumbs = Array.of(homepageCrumb)

  const Rummy = 'rummy'
  const rummyVariantSlugs = [
    '10-card-rummy',
    'indian-rummy',
    '21-card-rummy',
    'dummy-rummy',
    '500-rummy',
    'canasta',
    'chinese-rummy',
    'international-rummy',
    'continental-rummy-or-international-rummy',
    'crazy-crummy-rummy',
    'gin-rummy',
    '11-card-rummy',
    '7-card-rummy',
    'liverpool-rummy',
    'hollywood-rummy',
    'russian-rummy',
    'kalooki-rummy',
    'london-rummy',
    'mahjong-rummy',
    'mexican-rummy',
    'oklahoma-rummy',
    'poker-rummy',
    'pool-rummy',
    'rummikub',
    'speed-rummy',
    '3-card-rummy',
  ]

  const rummyLoginSlugs = [
    'rummy-golds-review',
    'similar-app-like-rummy-gold',
    'rummy-gold-withdrawal-how-to-withdraw-and-deposit-money',
    'glory-rummy-review',
    'glory-rummy-withdrawal-how-to-withdraw-and-deposit-money',
    'rummy-palace-review',
    'winning-tips-tricks-and-hacks-for-rummy-palace',
    'rummy-palace-withdrawal-how-to-withdraw-and-deposit-money',
    'rummy-palace-bonus-code',
    'how-to-withdraw-and-deposit-money-in-rummy-modern',
    'rummy-modern-review',
    'similar-apps-rummy-modern',
    'rummy-modern-hacks-winning-tips-tricks-and-strategy',
    'rummy-loot-review',
    'winning-tips-tricks-and-hacks-for-rummy-loot',
    'rummy-loot-withdrawal-how-to-withdraw-and-deposit-money',
    'rummy-loot-offer',
    'genesis-casino-vs-getmega',
    'how-to-withdraw-and-deposit-money-from-genesis-casino',
    'similar-app-gin-rummy-plus',
    'gin-rummy-plus-redeem-code',
    'gin-rummy-plus-review',
    'candy-rummy-review',
    'withdrawal-and-deposit-666-rummy',
    '666-rummy-review',
    '6ixxer-rummy-review',
    '777-rummy-review',
    'cheer-rummy-withdrawal-how-to-withdraw-and-deposit-money',
    'cheers-rummy-review',
    'color-rummy-review',
    'color-rummy-withdrawal-how-to-withdraw-and-deposit-money',
    'winning-tips-tricks-and-hacks-for-color-rummy',
    'daily-rummy-review',
    'deccan-rummy-coupon-codes',
    'deccan-rummy-customer-care-number-chat-support',
    'deccan-rummy-review',
    'deccan-rummy-withdrawal-how-to-withdraw-and-deposit-money',
    'play-deccan-rummy-online-cash-game-and-earn-real-cash',
    'declare-rummy-review',
    'dhan-rummy-withdrawal-how-to-withdraw-and-deposit-money',
    'gentle-rummy-review-2',
    'happy-ace-rummy-review',
    'hello-rummy-review',
    'hello-rummy-trick-hacks-and-winning-strategies',
    'hello-rummy-withdrawal-how-to-withdraw-and-deposit-money',
    'holy-rummy-withdrawal-how-to-withdraw-and-deposit',
    'winning-tips-tricks-and-hacks-for-royally-rummy-3',
    'how-to-withdraw-and-deposit-to-indifun-rummy',
    'indifun-rummy-coupon-codes',
    'indifun-rummy-review',
    'indigo-rummy-review',
    'king-rummy-review',
    'king-rummy-withdrawal-how-to-withdraw-and-deposit-money',
    'octro-rummy-coupon-codes',
    'octro-rummy-review',
    'octro-rummy-withdrawal-how-to-withdraw-and-deposit-money',
    'osom-rummy-coupon-codes',
    'osom-rummy-review',
    'osom-rummy-withdrawal-how-to-withdraw-and-deposit-money',
    'palm-rummy-review',
    'r-rummy-review',
    'r-rummy-withdrawal-how-to-withdraw-and-deposit-money',
    'roz-rummy-review',
    'roz-rummy-withdrawal-how-to-withdraw-and-deposit-money',
    'rummy-apna-review',
    'rummy-apna-withdrawal-how-to-withdraw-and-deposit-money',
    'rummy-ares-withdrawal-how-to-withdraw-and-deposit-money',
    'winning-tips-tricks-and-hacks-for-rummy-ares',
    'rummy-baazi-promo-code',
    'rummy-baazi-review',
    'rummy-bindaas-review',
    'rummy-central-review',
    'rummy-city-review',
    'ruumy-city-withdrawal-how-to-withdraw-and-deposit-money',
    'rummy-cool-review',
    'rummy-cool-withdrawal-how-to-withdraw-and-deposit-money',
    'rummy-dangal-coupon-codes',
    'rummy-dangal-review',
    'rummy-dangal-vs-getmega',
    'rummy-dangal-withdrawal-how-to-withdraw-and-deposit-money',
    'rummy-drive-review',
    'rummy-expert-club-review',
    'rummy-expert-club',
    'rummy-goal-review',
    'rummy-hero-review',
    'rummy-hero-withdrawal-how-to-withdraw-and-deposit-money',
    'rummy-league-review',
    'rummy-league-withdrawal-how-to-withdraw-and-deposit-money',
    'rummy-luxury-review',
    'rummy-mania-combo-review',
    'rummy-master-review',
    'withdrawal-and-deposit-rummy-master',
    'rummy-monster-review',
    'withdrawal-and-deposit-of-rummy-monster',
    'rummy-ola-review',
    'rummy-ola-withdrawal-how-to-withdraw-and-deposit-money',
    'similar-apps-rummy-ola',
    'winning-tips-tricks-and-hacks-rummy-ola',
    'rummy-party-withdrawal-how-to-withdraw-and-deposit-money',
    'rummy-perfect-withdrawal-how-to-withdraw-and-deposit-money',
    'similar-apps-rummy-perfect',
    'winning-tips-tricks-and-hacks-for-rummy-perfect',
    'rummy-posh-withdrawal-how-to-withdraw-and-deposit-money',
    'rummy-rafael-review',
    'rummy-rollie-review',
    'rummy-shark-review-2',
    'rummy-shark-withdrawal-how-to-withdraw-and-deposit-money',
    'rummy-soul-review',
    'rummy-soul-withdrawal-how-to-withdraw-and-deposit-money',
    'rummy-sun-review',
    'rummy-sunday-withdrawal-how-to-withdraw-and-deposit',
    'holy-rummy-withdrawal-how-to-withdraw-and-deposit-money',
    'rummy-villa-review',
    'rummy-villa-withdrawal-how-to-withdraw-and-deposit-money',
    'rummy-warrior-review-2',
    'taj-rummy-coupon-codes',
    'taj-rummy-review',
    'taj-rummy-withdrawal-how-to-withdraw-and-deposit-money',
    'ultimate-rummy-coupon-codes',
    'ultimate-rummy-review',
    'winning-tips-tricks-and-hacks-for-ultimate-rummy',
    'withdrawal-and-deposit-for-ultimate-rummy',
    'wow-rummy-review',
    'wow-rummy-withdrawal-how-to-withdraw-and-deposit-money-2',
    'yono-rummy-withdrawal-how-to-withdraw-and-deposit',
    'how-to-withdraw-and-deposit-money-from-ace2three',
    'ace2three-bonus-code',
    'ace2three-customer-number-chat-support',
    'ace2three-login-process-and-getting-started-with-app',
    'ace2three-review',
    'ace2three-vs-getmega',
    'best-ace2three-hack-for-you-to-play-and-earn-money',
    'best-apps-like-big-cash',
    'how-to-withdraw-and-deposit-money-from-big-cash',
    'winning-tips-for-big-cash',
    'bunga365-bonus-code',
    'bunga365-vs-getmega',
    'bunga365-withdrawal-how-to-withdraw-and-deposit-money',
    'how-to-withdraw-and-deposit-money-to-rummy-time',
    'rummy-time-company-profile-and-data',
    'rummy-time-coupon-code',
    'rummy-time-customer-number-chat-support',
    'rummy-time-login-process-and-getting-started-with-app',
    'rummy-time-online-play-earn-money',
    'rummy-time-referral-code-and-refer-and-earn-program',
    'rummy-time-review',
    'rummy-time-update-app-and-how-to-download',
    'rummy-time-vs-getmega',
    'best-rummy-time-hack',
    'skillclash-coupon-codes',
    'casino-days-bonus-code',
    'casino-days-vs-getmega',
    'how-to-withdraw-and-deposit-money-from-casino-days',
    'what-are-some-famous-sites-like-casumo-find-the-details-here',
    'best-casumo-tricks',
    'casumo-bonus-code',
    'casumo-online-casino-games-dil-bhar-ke-khelo',
    'casumo-vs-getmega',
    'classic-rummy-50rs-welcome-bonus-and-signup',
    'classic-rummy-coupon-codes',
    'classic-rummy-customer-care-number-and-chat-support',
    'classic-rummy-indian-card-game-play-earn',
    'classic-rummy-login-process-and-getting-started-with-the-app',
    'classic-rummy-paytm-offers-and-cashback',
    'classic-rummy-referral-code-and-refer-and-earn-program',
    'classic-rummy-review',
    'classic-rummy-tricks-tips-to-win-game',
    'classic-rummy-vs-getmega',
    'classic-rummy-withdrawal-how-to-withdraw-winnings',
    'how-to-delete-classic-rummy-account-verify-details-kyc',
    'how-to-withdraw-deposit-money-from-funbet',
    'funbet-coupon-codes',
    'funbet-vs-getmega-1',
    'gully-rummy-promo-code',
    'gully-rummy-review',
    'gully-rummy-vs-getmega',
    'how-to-withdraw-deposit-money-from-gully-rummy',
    'apps-websites-similar-to-jackpot-city',
    'how-to-withdraw-and-deposit-money-from-jackpot-city',
    'how-to-play-and-earn-money-on-junglee-rummy',
    'how-to-withdraw-and-deposit-money-from-junglee-rummy',
    'junglee-rummy-app-se-paise-kaise-kmaae',
    'junglee-rummy-game-rules',
    'junglee-rummy-history-and-owner-details',
    'junglee-rummy-kyc-update-online',
    'junglee-rummy-login',
    'junglee-rummy-loyalty-program',
    'junglee-rummy-promo-code',
    'junglee-rummy-review',
    'junglee-rummy-tournaments',
    'junglee-rummy-vs-getmega',
    'similar-app-like-junglee-rummy',
    'the-legality-of-junglee-rummy',
    'best-junglee-rummy-hack',
    'customer-support-and-contact-details-of-junglee-rummy',
    'how-to-change-personal-details-in-junglee-rummy',
    'how-to-download-junglee-desktop-version-on-pc-and-laptop',
    'how-to-download-junglee-rummy-culture-app-or-apk-for-ios',
    'how-to-download-junglee-rummy-lite-app-or-apk',
    'khelplay-coupon-codes',
    'khelplay-rummy-review',
    'khelplay-rummy-vs-getmega',
    'leovegas-casino-vs-getmega',
    'magic-rummy-coupon-codes',
    'magic-rummy-review',
    'magic-rummy-vs-getmega',
    'magic-rummy-withdrawal',
    'myteamrummy-review',
    'myteamrummy-vs-getmega',
    'oppa888-coupon-codes',
    'oppa888-vs-getmega',
    'parimatch-vs-getmega-2',
    'what-are-the-best-apps-like-parimatch',
    'best-parimatch-hack-for-you-to-play-and-earn-money',
    'how-to-withdraw-money-from-parimatch',
    'playrummy-coupon-codes',
    'playrummy-review',
    'playrummy-vs-getmega',
    'how-to-withdraw-and-deposit-money-from-playrummy',
    'royal-panda-coupon-codes',
    'royal-panda-vs-getmega',
    'royal-panda-withdrawal-how-to-withdraw-and-deposit-money',
    'best-royal-panda-tips',
    'similar-app-like-rummy-circle',
    'the-legality-of-rummy-circle-app',
    'about-rummy-circle-saturday-showdown',
    'best-rummy-circle-hack-for-you-to-play-and-earn-money',
    'fair-play-policy-of-rummy-circle',
    'friday-favorites-tournament-in-rummy-circle',
    'how-to-change-personal-details-in-rummy-circle-2',
    'how-to-change-personal-details-in-rummy-circle',
    'how-to-update-your-kyc-in-rummy-circle',
    'rummy-circle-clubs-rewards-and-loyalty-program',
    'rummy-circle-company-profile-and-data-total',
    'rummy-circle-free-games-and-practice-games',
    'rummy-circle-game-rules-rummy-terms-quick-tips',
    'rummy-circle-game-rules',
    'rummy-circle-login-registration-process-to-get-started',
    'rummy-circle-paytm-cash-loot-offers',
    'rummy-circle-promo-code',
    'rummy-circle-review',
    'rummy-circle-vs-getmega-2',
    'the-legality-of-rummy-culture',
    'how-to-download-rummy-culture-app-or-apk-for-pc',
    'how-to-hack-rummy-culture',
    'how-to-withdraw-cash-from-rummy-culture',
    'rummy-culture-bonus',
    'rummy-culture-company-profile',
    'rummy-culture-customer-care',
    'rummy-culture-promo-code',
    'rummy-culture-referral-code-and-refer-and-earn-program-total',
    'rummy-culture-review',
    'rummy-culture-tds-total',
    'rummy-culture-vs-getmega',
    'rummy-go-coupon-codes-and-bonus-offers',
    'rummy-go-review',
    'rummy-go-vs-getmega',
    'silkrummy-review',
    'silkrummy-vs-getmega',
    'silkrummy-withdrawal-how-to-withdraw-and-deposit-money',
    'spin-casino-vs-getmega',
    'best-yahoo-fantasy-tips',
    'how-to-withdraw-deposit-money-from-yahoo-fantasy',
    'casoola-bonus-code',
    'casoola-vs-getmega',
    'casoola-withdrawal-time-and-know-everything-about-withdrawal-and-deposit',
    'rummy-silver-review',
    'rummy-24-review',
    'how-to-withdraw-and-deposit-to-rummy-go-x',
    'rummy-station-review',
    'rummy-vs-withdrawal-how-to-withdraw-and-deposit',
    'rummy-vs-withdrawal-how-to-withdraw-and-deposit',
    'dream-rummy-withdrawal-how-to-withdraw-and-deposit-money-2',
    'rummy-elite-withdrawal-how-to-withdraw-and-deposit-to-rummy-elite',
    'rummy-winner-withdrawal-how-to-withdraw-and-deposit-to-rummy-winner',
    'dash-rummy-review',
    'dash-rummy-withdrawal-how-to-withdraw-and-deposit-money',
    'rummy-pub-review',
    'rummy-pub-withdrawal-how-to-withdraw-and-deposit-money',
    '9f-games-rummy-withdrawal-how-to-withdraw-and-deposit-money',
    'lucky-rummy-promo-codes',
    'lucky-rummy-review',
    'lucky-rummy-withdrawal-how-to-withdraw-and-deposit-money',
    'winning-tips-tricks-and-hacks-for-royal-rummy',
    'royal-rummy-review',
    'royal-rummy-withdrawal-how-to-withdraw-and-deposit-money',
    'winning-tips-tricks-and-hacks-for-royal-rummy',
    'rummy-buzz-review',
    'rummy-cafe-review',
    'rummy-cafe',
    'rummy-crown-review',
    'rummy-crown-withdrawal-how-to-withdraw-and-deposit-money',
    'rummy-domain-review',
    'rummy-galaxy-review',
    'rummy-gill-review',
    'rummy-grand-review',
    'rummy-grand-withdrawal-how-to-withdraw-and-deposit-money',
    'rummy-hotel-review',
    'rummy-jacks-review',
    'rummy-king-coupon-codes',
    'rummy-king-review',
    'rummy-king-withdrawal-how-to-withdraw-and-deposit-money',
    'rummy-model-review',
    'rummy-plus-coupon-codes',
    'rummy-plus-review',
    'winning-tips-tricks-and-hacks-for-rummy-pro',
    'withdrawal-and-deposit-rummy-pro',
    'rummy-pro-coupon-codes',
    'rummy-pro-review',
    'rummy-raja-review',
    'rummy-raja-withdrawal-how-to-withdraw-and-deposit-money',
    'winning-tips-tricks-and-hacks-for-rummy-rumble',
    'rummy-rumble-review',
    'rummy-vungo-review',
    'rummy-vungo-withdrawal-how-to-withdraw-and-deposit-money',
    'winning-tips-tricks-and-hacks-rummy-yes',
    'rummy-yes-coupon-codes',
    'rummy-yes-withdrawal-how-to-withdraw-and-deposit-money',
    'win-101-rummy-review-2',
    'win-101-rummy-withdrawal-how-to-withdraw-and-deposit-money',
    'winning-tips-tricks-and-hacks-for-royally-rummy',
    'rummy-leopard-withdrawal-how-to-withdraw-and-deposit',
    'withdrawal-and-deposit-adda52rummy',
    'adda52-rummy-coupons',
    'relish-rummy-review',
    'rummy-4-u-review',
    'rummycue-review-2',
    'dhani-rummy-review',
    'dhani-rummy-withdrawal-how-to-withdraw-and-deposit-money',
    'rummy-guru-cash-coupon-codes',
    'rummy-guru-review',
    'rummy-guru-withdrawal-how-to-withdraw-and-deposit-money',
    'rummy-legend-review',
    'prime-rummy-review',
    'ruby-rummy-review',
    'ruby-rummy-withdrawal-how-to-withdraw-and-deposit-money',
    'rummy-baba-review',
    'rummy-baba-withdrawal-how-to-withdraw-and-deposit-money',
    'rummy-birkin-withdrawal-how-to-withdraw-and-deposit-money',
    'rummy-blast-review',
    'rummy-blast-withdrawal-how-to-withdraw-and-deposit-money',
    'rummy-buddy-review',
    'winning-tips-tricks-and-hacks-for-rummy-club',
    'rummy-club-review',
    'rummy-club-withdrawal-how-to-withdraw-and-deposit-money',
    'rummy-duke-review',
    'rummy-east-offer',
    'rummy-east-review',
    'rummy-east-winning-tips-tricks-and-hacks',
    'rummy-eastwithdrawal-how-to-withdraw-and-deposit-money',
    'rummy-fate-review',
    'rummy-ganesh-review',
    'rummy-ganesh-withdrawal-how-to-withdraw-and-deposit-money',
    'rummy-ho-review',
    'rummy-jadoo-review',
    'rummy-jadoo-withdrawal-how-to-withdraw-and-deposit-money',
    'rummy-land-review',
    'rummy-mantra-review',
    'rummy-max-review',
    'rummy-millionaire-coupon-codes',
    'rummy-millionaire-review',
    'rummy-nation-review',
    'rummy-star-coupon-codes',
    'rummy-star-review',
    'rummy-star-withdrawal-how-to-withdraw-and-deposit-money',
    'rummy-tour-review',
    'rummy-tour-withdrawal-how-to-withdraw-and-deposit-money',
    'rummy-world-review',
    'similar-app-rummy-world',
    'winning-tips-tricks-and-hacks-for-rummy-world',
    'rummy-world-withdrawal-how-to-withdraw-and-deposit-money',
    'crazy-rummy-review',
    'fun-rummy-club-withdrawal-how-to-withdraw-and-deposit-money',
    'fun-rummy-review',
    'rich-rummy-review',
    'rummy-deluxe-review',
    'rummy-deluxe-withdrawal-how-to-withdraw-and-deposit-money',
    'how-to-download-login-rummy-passion-desktop-app-for-pc',
    'how-to-withdraw-and-deposit-money-from-rummy-passion',
    'rummy-passion-apk-download-for-android-and-ios',
    'rummy-passion-company-details-and-origin',
    'rummy-passion-coupon-codes',
    'rummy-passion-login-process-and-getting-started-with-app',
    'rummy-passion-online-rummy-game-play-earn',
    'rummy-passion-review',
    'rummypassion-vs-getmega',
    'vegas-rummy-review',
  ]

  let breadcrumbs: Breadcrumb[]
  if (rummyVariantSlugs.indexOf(post.slug) > -1) {
    breadcrumbs = Array.of(
      ...baseRummyBreadcrumbs,
      getParentCrumb(prefix),
      rummyVariantsCrumb
    )
  } else if (post.slug === 'rummy-the-card-game') {
    breadcrumbs = Array.of(...baseRummyBreadcrumbs, getParentCrumb(prefix))
  } else {
    breadcrumbs = Array.of(...baseRummyBreadcrumbs, getParentCrumb(prefix))
  }

  if (prefixCat === Rummy && post.slug !== 'rummy-the-card-game') {
    breadcrumbs.push(getSelfCrumbRummy(prefix, post))
  } else {
    breadcrumbs.push(getSelfCrumb(prefix, post))
  }
  return breadcrumbs
}

export default getBreadcrumbs
