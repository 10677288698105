import React, { useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import './BlogTemplate.scss'
import RealPlayers from './RealPlayers'
import RummyBanners from './learn-rummy/RummyBanners'
import GameBottomContent from './GameBottomContent'
import useTestimonials from '../components/Testimonials/useTestimonials'
import { Faq } from '../components/FAQs/faqs-list-interface'
import { LATEST } from '../components/download-links'

interface Props {
  postContent: React.ReactNode
  similarContent: React.ReactNode
  faqData?: Faq[]
}

const BlogLayout: React.FC<Props> = ({
  postContent,
  similarContent,
  faqData,
}) => {
  const [rummyFaqs2] = useState<Faq[]>([])

  const handlePlay = () => {
    window.open(LATEST, '_blank')
  }

  const { rummyTestimonials } = useTestimonials()

  const rummyLayout = (
    <>
      <div className="blog">
        <Container>
          <Row>
            {postContent}
            <Col>
              <RummyBanners position="side" openPopup={handlePlay} />
            </Col>
          </Row>
          <Row>
            <Col lg={8}>{similarContent}</Col>
          </Row>
        </Container>
      </div>
      <RealPlayers apkLink={LATEST} />
      <Container>
        <Row>
          <RummyBanners position="bottom" openPopup={handlePlay} />
        </Row>
      </Container>
      <div>
        <GameBottomContent
          faqsType={faqData || rummyFaqs2}
          testimonialType={rummyTestimonials}
        />
      </div>
    </>
  )

  return <>{rummyLayout}</>
}

export default BlogLayout
