const fetchSimilarArticles = (content: any) => {
  const { html } = content

  const postContent = document?.createElement('div')
  postContent.innerHTML = `${html}`

  const similarArticlesTable = postContent?.querySelector(
    '#blog-similar-articles'
  )?.nextElementSibling

  const similarArticlesArr = []

  if (similarArticlesTable) {
    const rows = similarArticlesTable.getElementsByTagName('tr')

    for (let i = 1; i < rows.length; i += 1) {
      const tds = rows[i].getElementsByTagName('td')
      if (tds[0].childNodes.length && tds[1].childNodes.length) {
        similarArticlesArr.push({
          id: i,
          title: tds[0].textContent,
          slug: tds[1].textContent,
        })
      }
    }
  }
  return similarArticlesArr
}
export default fetchSimilarArticles
