import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import './RealPlayers.scss'
import LivePlayersCount from '../components/Games/LivePlayersCount/LivePlayersCount'

interface Props {
  apkLink?: string
}

const RealPlayers: React.FC<Props> = ({ apkLink }) => {
  return (
    <div className="real-players">
      <Container>
        <Row>
          <Col>
            <LivePlayersCount
              apkLink={apkLink}
              text={
                <>
                  <div>Learnt something new ?</div>
                  <div>
                    Try your skills with{' '}
                    <span className="underline">real Rummy</span> Players
                  </div>
                </>
              }
              showButton
            />
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default RealPlayers
