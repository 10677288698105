import { Link } from 'gatsby'
import React, { useEffect, useState } from 'react'
import fetchSimilarArticles from './getSimilarArticles'
import DefaultFeatureImage from './learn-rummy/defaultFeatureImage'

interface Props {
  post: any
  fetchBlog: any[]
  similarArticles: any[]
  prefix: string
  prefixCat?: string
}
const BlogSimilarArticles: React.FC<Props> = ({
  post,
  fetchBlog,
  similarArticles,
  prefix,
  prefixCat,
}) => {
  const [similarArticlesCMS, setSimilarArticlesCMS] = useState<any>([])

  useEffect(() => {
    setSimilarArticlesCMS(fetchSimilarArticles(post))
  }, [post])

  const defaultImage = prefixCat ? (
    <DefaultFeatureImage game={prefixCat} preview />
  ) : null

  const randomSimilar =
    similarArticles.filter((s: any) => s).length > 0 ? (
      <div className="similar-articles-wrap">
        <div className="similar-articles-title">
          More <span className="underline">articles</span> Like this
        </div>
        <div className="similar-articles">
          {similarArticles
            .filter((s: any) => s)
            .map((s: any) => s.node)
            .map((s: any) => {
              return (
                <div className="s-article" key={s.slug}>
                  <Link to={`/${prefix}/${s.slug}/`}>
                    <div className="feature-image">
                      {s.feature_image ? (
                        <img
                          width={195}
                          height={83}
                          src={s.feature_image}
                          alt={s.title}
                        />
                      ) : (
                        'image here'
                      )}
                    </div>
                    <div className="title">{s.title}</div>
                  </Link>
                </div>
              )
            })}
        </div>
        <div>
          <Link to={`/${prefix}/`} className="view-all-articles">
            view all articles
          </Link>
        </div>
      </div>
    ) : null

  const similarFromCMS =
    similarArticlesCMS.filter((s: any) => s).length > 0 ? (
      <div className="similar-articles-wrap">
        <div className="similar-articles-title">
          More <span className="underline">articles</span> Like this
        </div>
        <div className="similar-articles">
          {fetchBlog.map((s: any) => {
            if (
              similarArticlesCMS
                .slice(0, 7)
                .some((obj: { slug: any }) => obj.slug === s.slug)
            ) {
              return (
                <div className="s-article" key={s.slug}>
                  <Link to={`/${prefix}/${s.slug}/`}>
                    <div className="feature-image">
                      {s.feature_image ? (
                        <img
                          width={195}
                          height={83}
                          src={s.feature_image}
                          alt={s.title}
                        />
                      ) : (
                        defaultImage
                      )}
                    </div>
                    <div className="title">{s.title}</div>
                  </Link>
                </div>
              )
            }
            return false
          })}
        </div>
        <div>
          <Link to={`/${prefix}/`} className="view-all-articles">
            view all articles
          </Link>
        </div>
      </div>
    ) : null

  return similarArticlesCMS.length ? similarFromCMS : randomSimilar
}
export default BlogSimilarArticles
