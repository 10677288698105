import React from 'react'
import './Share.scss'
import { StaticImage } from 'gatsby-plugin-image'

import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from 'react-share'

interface Props {
  title: string
  url: string
}

const ShareButtons: React.FC<Props> = ({ title, url }) => {
  return (
    <div id="share-icons">
      <span className="share-text">Share</span>
      <FacebookShareButton url={url} className="fb-button">
        <StaticImage
          src="../../images/icons8-facebook.svg"
          alt="facebook-icon"
          loading="eager"
        />
      </FacebookShareButton>

      <TwitterShareButton url={url} title={title}>
        <StaticImage
          src="../../images/icons8-twitter.svg"
          alt="twitter-icon"
          loading="eager"
        />
      </TwitterShareButton>

      <LinkedinShareButton url={url} title={title}>
        <StaticImage
          src="../../images/icons8-linkedin-2.svg"
          alt="linkedin-icon"
          loading="eager"
        />
      </LinkedinShareButton>

      <WhatsappShareButton url={url} title={title}>
        <StaticImage
          src="../../images/icons8-whatsapp.svg"
          alt="whatsapp-icon"
          loading="eager"
        />
      </WhatsappShareButton>
    </div>
  )
}

export default ShareButtons
