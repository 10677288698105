import { graphql, useStaticQuery } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import React from 'react'
import useRandomNumber from '../../../hooks/useRandomNumber'
import LinkButton from '../../LinkButton/LinkButton'
import Title from '../../Title/Title'
import './LivePlayersCount.scss'

interface Props {
  text: string | React.ReactNode
  showButton?: boolean
  apkLink?: string
}

const LivePlayersCount: React.FC<Props> = ({
  text,
  showButton = false,
  apkLink,
}) => {
  const data = useStaticQuery(graphql`
    {
      players: file(relativePath: { eq: "live-players.png" }) {
        childImageSharp {
          gatsbyImageData(width: 344, layout: CONSTRAINED)
        }
      }
    }
  `)
  const count = useRandomNumber(4000, 6000)

  return (
    <div id="LivePlayersCount">
      <Title color="#000">{text}</Title>
      <p color="#37D6C2" className="count">
        +
        {count
          .toFixed(2)
          .replace(/(\d)(?=(\d{2})+\d\.)/g, '$1,')
          .replace('.00', '')}
      </p>
      <div className="stripes-wrap">
        <GatsbyImage
          image={data.players.childImageSharp.gatsbyImageData}
          alt="live players"
          imgStyle={{ objectFit: 'contain' }}
          className="image"
        />
        <div className="stripes" />
      </div>

      {showButton ? (
        <div className="popBtn-wrap">
          <LinkButton to={apkLink} className="popBtn">
            Play Now
          </LinkButton>
        </div>
      ) : null}
    </div>
  )
}

export default LivePlayersCount
