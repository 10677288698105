import React from 'react'
import HomeFAQs from '../components/Index/HomeFAQs/HomeFAQs'
import TestimonialsContainer from '../components/Index/Testimonials/TestimonialsContainer'
import { TestimonialProps } from '../components/Testimonials/Testimonial'
import { Faq } from '../components/FAQs/faqs-list-interface'

interface Props {
  faqsType?: Faq[]
  testimonialType?: TestimonialProps[]
}

const GameBottomContent: React.FC<Props> = ({ faqsType, testimonialType }) => {
  return (
    <>
      {testimonialType && (
        <div className="testimonial-wrap">
          <TestimonialsContainer testimonials={testimonialType} />
        </div>
      )}
      {faqsType && (
        <div className="faqs-wrap">
          <HomeFAQs faqs={faqsType} />
        </div>
      )}
    </>
  )
}

export default GameBottomContent
