import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'

interface Props {
  game: string
  preview?: boolean
}

const DefaultFeatureImage: React.FC<Props> = ({ game, preview = false }) => {
  const data = useStaticQuery(graphql`
    {
      poker: file(relativePath: { eq: "poker-blog-feature.jpg" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      pokerPreview: file(relativePath: { eq: "poker-blog-feature.jpg" }) {
        childImageSharp {
          gatsbyImageData(width: 195, layout: CONSTRAINED)
        }
      }
    }
  `)

  const pokerImage = (
    <GatsbyImage
      image={
        preview
          ? data.pokerPreview.childImageSharp.gatsbyImageData
          : data.poker.childImageSharp.gatsbyImageData
      }
      loading="eager"
      alt="blog"
      imgStyle={{ objectFit: 'contain' }}
    />
  )

  if (game === 'poker') {
    return pokerImage
  }
  return null
}

export default DefaultFeatureImage
