import { useStaticQuery, graphql } from 'gatsby'
import { IGatsbyImageData } from 'gatsby-plugin-image'
import { useMemo } from 'react'

type BannerPosition = 1 | 2 | 3

export interface RummyBanner {
  img: IGatsbyImageData
  position: BannerPosition
}

const useRummyBanners = (): RummyBanner[] => {
  const data = useStaticQuery(graphql`
    {
      side1: file(relativePath: { eq: "learn-rummy-banners/side_1.jpg" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      side2: file(relativePath: { eq: "learn-rummy-banners/side_2.jpg" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      side3: file(relativePath: { eq: "learn-rummy-banners/side_3.jpg" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      side4: file(relativePath: { eq: "learn-rummy-banners/side_4.jpg" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      side5: file(relativePath: { eq: "learn-rummy-banners/side_5.jpg" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      side6: file(relativePath: { eq: "learn-rummy-banners/side_6.jpg" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      bottom1: file(relativePath: { eq: "learn-rummy-banners/bottom_1.jpg" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      bottom2: file(relativePath: { eq: "learn-rummy-banners/bottom_2.jpg" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      bottom3: file(relativePath: { eq: "learn-rummy-banners/bottom_3.jpg" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      bottom4: file(relativePath: { eq: "learn-rummy-banners/bottom_4.jpg" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      bottom5: file(relativePath: { eq: "learn-rummy-banners/bottom_5.jpg" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      bottom6: file(relativePath: { eq: "learn-rummy-banners/bottom_6.jpg" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
    }
  `)

  const rummyBanners: RummyBanner[] = useMemo(
    () => [
      {
        img: data.side1.childImageSharp.gatsbyImageData,
        position: 1,
      },
      {
        img: data.side2.childImageSharp.gatsbyImageData,
        position: 1,
      },
      {
        img: data.side3.childImageSharp.gatsbyImageData,
        position: 1,
      },
      {
        img: data.side4.childImageSharp.gatsbyImageData,
        position: 1,
      },
      {
        img: data.side5.childImageSharp.gatsbyImageData,
        position: 1,
      },
      {
        img: data.side6.childImageSharp.gatsbyImageData,
        position: 1,
      },
      {
        img: data.bottom1.childImageSharp.gatsbyImageData,
        position: 2,
      },
      {
        img: data.bottom2.childImageSharp.gatsbyImageData,
        position: 2,
      },
      {
        img: data.bottom3.childImageSharp.gatsbyImageData,
        position: 2,
      },
      {
        img: data.bottom4.childImageSharp.gatsbyImageData,
        position: 2,
      },
      {
        img: data.bottom5.childImageSharp.gatsbyImageData,
        position: 2,
      },
      {
        img: data.bottom6.childImageSharp.gatsbyImageData,
        position: 2,
      },
    ],
    [data]
  )

  return rummyBanners
}

export default useRummyBanners
